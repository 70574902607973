<template>
  <div class="pages">
    <div class="houses-data">
      <div class="data-title">
        <span>基本信息</span>
      </div>
      <div class="data-info">
        <div class="item">
          <div class="title">
            <span>楼盘名称</span>
          </div>
          <div class="content">
            <span>{{ project?.name }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>开盘时间</span>
          </div>
          <div class="content">
            <span>{{ project?.base_data?.open_pending === 1 ? '待定' : project?.base_data?.open_date }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>楼盘特点</span>
          </div>
          <div class="tag-info">
            <div class="tag" v-for="(secondary_tag,secondary_tag_index) in project?.base_data?.secondary_tags"
                 :key="secondary_tag_index">
              <span>{{ secondary_tag.tag }}</span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>参考价格</span>
          </div>
          <div class="content">
            <span class="ac">{{ project?.base_data?.price_pending == 1 ? '待定' : project?.base_data?.price_start }}</span>
            <span v-show="project?.base_data?.price_pending != 1">元/㎡</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>开发商</span>
          </div>
          <div class="content">
            <span>{{project?.base_data?.developer}}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>区域位置</span>
          </div>
          <div class="content">
            <span>{{project?.city?.name}}-{{project?.area?.name}}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>楼盘地址</span>
          </div>
          <div class="content">
            <span>{{ project?.address }}</span>
            <span>[</span>
            <span class="ac">查看地图</span>
            <span>]</span>
          </div>
        </div>
      </div>
    </div>
    <div class="houses-data">
      <div class="data-title">
        <span>销售信息</span>
      </div>
      <div class="data-info">
<!--        <div class="item">-->
<!--          <div class="title">-->
<!--            <span>交房时间</span>-->
<!--          </div>-->
<!--          <div class="content">-->
<!--            <span>暂无资料</span>-->
<!--          </div>-->
<!--        </div>-->
        <div class="item">
          <div class="title">
            <span>产权年限</span>
          </div>
          <div class="content">
            <span>{{project?.base_data?.property_tenure}}年</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>售楼地址</span>
          </div>
          <div class="content">
            <span>{{project?.base_data?.sales_address}}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>在售户型</span>
          </div>
          <div class="content">
            <span>{{ project?.base_data?.types_for_sale }}</span>
            <span>[</span>
            <span class="ac">查看全部户型</span>
            <span>]</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>产品类型</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.product_type_text  }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>楼盘状态</span>
          </div>
          <div class="content">
            <span v-if="project?.base_data?.product_status_id === 1">全城热销中</span>
            <span v-if="project?.base_data?.product_status_id === 2">火爆加推</span>
            <span v-if="project?.base_data?.product_status_id === 3">预售进行中</span>
            <span v-if="project?.base_data?.product_status_id === 4">待售</span>
          </div>
        </div>
      </div>
    </div>
    <div class="houses-data">
      <div class="data-title">
        <span>小区概况</span>
      </div>
      <div class="data-info">
        <div class="item" v-if="project?.base_data?.parking_num != 0">
          <div class="title">
            <span>车位数量</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.parking_num  }}</span>
          </div>
        </div>
        <div class="item" v-if="project?.base_data?.parking_ratio != 0">
          <div class="title">
            <span>车位比</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.parking_ratio  }}%</span>
          </div>
        </div>
        <div class="item" v-if="project?.base_data?.building_num != 0">
          <div class="title">
            <span>楼栋总数</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.building_num  }}</span>
          </div>
        </div>
        <div class="item" v-if="project?.base_data?.house_num != 0">
          <div class="title">
            <span>总户数</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.house_num  }}</span>
          </div>
        </div>
        <div class="item" v-if="project?.base_data?.greening_rate != 0">
          <div class="title">
            <span>绿化率</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.greening_rate  }}%</span>
          </div>
        </div>
        <div class="item" v-if="project?.base_data?.volume_rate">
          <div class="title">
            <span>容积率</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.volume_rate  }}%</span>
          </div>
        </div>
        <div class="item" v-if="project?.base_data?.product_type_id">
          <div class="title">
            <span>物业类型</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.property_type_text  }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>物业公司</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.property_company  }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>物业费用</span>
          </div>
          <div class="content">
            <span>{{  project?.base_data?.property_price  }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>供水方式</span>
          </div>
          <div class="content">
            <span v-if="project?.base_data?.water_supply_method === 1">民水</span>
            <span v-if="project?.base_data?.water_supply_method === 2">商水</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>供电方式</span>
          </div>
          <div class="content">
            <span v-if="project?.base_data?.power_supply_method === 1">民电</span>
            <span v-if="project?.base_data?.power_supply_method === 2">商电</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>供暖方式</span>
          </div>
          <div class="content">
            <span v-if="project?.base_data?.heating_supply_method === 1">集中供暖</span>
            <span v-if="project?.base_data?.heating_supply_method === 2">自供暖</span>
            <span v-if="project?.base_data?.heating_supply_method === 3">不供暖</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HousesInfo",
  data() {
    return {
      project: false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      let res = await this.http({
        url: '/api/report/projectInfo',
        method: 'GET',
        params: {
          project_id: this.$route.query.id
        }
      })

      res.data.base_data.primary_tag = res.data.base_data.primary_tags[0]?.tag || null

      console.log(res.data)
      this.project = res.data
      console.log(this.project)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "HousesInfo.scss";
</style>
